import { useEffect, useRef, useState } from 'react';
import { DropTarget } from 'react-drag-drop-container';
import { useCustomWindowContext } from '../../../containers/CustomWindow/customWindowContext';
import {
	openModalAction,
	selectCellsAction,
	deleteProductAction,
	editMenuToggleAction,
} from '../../../containers/CustomWindow/Actions';
import { canDropProduct, isEqual } from '../../../helpers/Comparators';
import classNames from 'classnames';
import './styles.css';
import { useUIManagementContext } from '../../../contexts/UIManagementContext';

const GridCell = ({ cell, inMultiUnitPath, overrideClick, x, y }) => {
	const { toggleOverlay } = useUIManagementContext();
	const customWindowDispatch = inMultiUnitPath
		? useCustomWindowContext().customWindowDispatch
		: null;
	const customWindowState = inMultiUnitPath
		? useCustomWindowContext().customWindowState
		: null;
	const editMenuToggleValue = inMultiUnitPath
		? customWindowState.editMenuToggleValue
		: null;
	const selectedCells = inMultiUnitPath ? customWindowState.selectedCells : [];

	const { key, location, product } = cell;

	const editMenuNode = useRef();
	const [isSelected, setSelected] = useState(false);
	const [highlightBackground, setBackground] = useState(false);

	const isEditMenuActive = isEqual(overrideClick ? 0 : editMenuToggleValue, location);

	useEffect(() => {
		if (!overrideClick) {
			const exists = selectedCells.some((item) => item.key === cell.key);
			if (exists) {
				setSelected(true);
			}
		}
	}, [cell]);

	/*
		After the dispatch call, our openModalState value is toggled (bool)
		and we can close/show the modal. However, this timeout pauses the button 
		click on the edit/delete buttons in the custom layout window portal.
	*/
	const toggleMenu = (e) => {
		if (editMenuNode.current && !isEditMenuActive) {
			if (editMenuNode.current.contains(e.target) && e.type === 'click') {
				customWindowDispatch(editMenuToggleAction(location));
			}
		} else if (isEditMenuActive) {
			customWindowDispatch(editMenuToggleAction(0));
		}
	};

	useEffect(() => {
		// add when mounted
		document.addEventListener('mousedown', toggleMenu);
		// return function to be called when unmounted
		return () => {
			document.removeEventListener('mousedown', toggleMenu);
		};
	}, []);

	if (!overrideClick) {
		useEffect(() => {
			setSelected(selectedCells.includes(cell));
		}, [selectedCells]);
	}

	const droppedHandleEvent = ({ dragData }) => {
		const result = canDropProduct(cell, dragData);
		if (!product.droppedData && result?.canDrop) {
			customWindowDispatch(
				openModalAction({ col: cell, droppedData: dragData, x, y })
			);
			handleMouseLeave();
		} else {
			// If the user cannot drop a product.
			handleMouseLeave();

			// Otherwise, show an error modal.
			toggleOverlay({
				active: true,
				type: 'error_messages',
				data: {
					heading: 'Error Placing Window In Cell',
					errors: [result.message],
				},
			});
		}
	};

	const selectColumnHandler = () => {
		customWindowDispatch(selectCellsAction(cell));
		setSelected(!isSelected);
	};

	const tdBorderHandler = () => {
		if (product.droppedData) {
			return 'border-solid border-ws-light-blue bg-ws-x-light-blue';
		} else if (isSelected) {
			return 'border-solid border-ws-blue bg-ws-m-light-blue';
		}
		return 'border-dashed border-ws-light-blue bg-ws-x-light-blue';
	};

	// Shows the edit product menu.
	const editProduct = () => {
		customWindowDispatch(
			openModalAction({
				col: cell,
				droppedData: product.droppedData,
				product,
			})
		);
		customWindowDispatch(editMenuToggleAction(0));
	};

	// Just deletes the product. There is no confirmation modal.
	const deleteProduct = () => {
		customWindowDispatch(deleteProductAction({ key }));
	};

	const activeEditMenuBtnStyle = isEditMenuActive
		? 'focus:outline-none ring-2 ring-offset-2 ring-offset-gray-100  bg-ws-blue'
		: ' bg-ws-gray ';

	const handleMouseEnter = () => {
		setBackground(true);
	};

	const handleMouseLeave = () => {
		setBackground(false);
	};

	const editButtonStyle = `inline-flex justify-center h-9 w-9 border-gray-300 rounded-bl-sm border shadow-sm px-4 py-2 text-sm font-medium text-gray-700 hover:bg-ws-blue ${activeEditMenuBtnStyle}`;

	const editContainerStyle =
		'absolute right-0 w-40 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none';

	const editButtonEndStyle =
		'flex flex-row items-center px-4 py-2 text-base text-ws-dark-gray hover:text-ws-blue group';

	const editButtonEndSpan =
		'flex items-center justify-center w-6 h-6 mr-2 text-xs rounded icon-fas icon--pencil bg-ws-gray text-ws-gray-white group-hover:bg-ws-blue';

	const deleteButtonSpan =
		'flex items-center justify-center w-6 h-6 mr-2 text-xs rounded icon-fas icon--delete bg-ws-gray text-ws-gray-white group-hover:bg-ws-red';

	const deleteButtonStyle =
		'flex flex-row items-center px-4 py-2 text-base text-ws-dark-gray hover:text-ws-red group';

	const buttonStyle = classNames(
		'absolute top-1 right-1 border-solid w-9 h-9 flex justify-center items-center',
		{
			'text-white bg-ws-blue border border-ws-blue': isSelected,
			'border border-gray-400 text-gray-400': !isSelected,
		}
	);

	return (
		<>
			<DropTarget
				targetKey="window"
				onDragEnter={handleMouseEnter}
				onDragLeave={handleMouseLeave}
				onHit={droppedHandleEvent}
			>
				<div
					className={`h-full relative rounded-md p-4 w-full ${tdBorderHandler()} ${
						highlightBackground ? 'bg-ws-blue' : ''
					}`}
				>
					{!overrideClick && !product.droppedData && (
						<button onClick={selectColumnHandler} className={buttonStyle}>
							<span className="icon-far icon--check" />
						</button>
					)}
					{product.droppedData && (
						<img
							src={product.droppedData.img}
							className="absolute top-0 bottom-0 left-0 right-0 w-full h-full"
						/>
					)}
					{/* Delete/Edit menu */}
					{!overrideClick && product.droppedData && (
						<div className="absolute top-0 right-0 z-10 ">
							<div className="relative inline-block text-left">
								<button
									type="button"
									className={editButtonStyle}
									id={`menu-button-${key}`}
									aria-expanded="true"
									aria-haspopup="true"
									ref={editMenuNode}
									onClick={toggleMenu}
								>
									<span className="text-white icon-fas icon--pencil" />
								</button>
								{/* Small modal that allows a user to edit or delete a placed window. */}
								{isEditMenuActive && (
									<div
										className={editContainerStyle}
										role="menu"
										aria-orientation="vertical"
										aria-labelledby={`menu-button-${key}`}
										tabIndex="-1"
									>
										<div className="py-1" role="none">
											<button
												className={editButtonEndStyle}
												role="menuitem"
												tabIndex="-1"
												id="menu-item-0"
												onClick={editProduct}
											>
												<span className={editButtonEndSpan} />
												Edit
											</button>
											<div className="py-1" role="none">
												<button
													className={deleteButtonStyle}
													role="menuitem"
													tabIndex="-1"
													id="menu-item-6"
													onClick={deleteProduct}
												>
													<span className={deleteButtonSpan} />
													Delete
												</button>
											</div>
										</div>
									</div>
								)}
							</div>
						</div>
					)}
				</div>
			</DropTarget>
		</>
	);
};

export default GridCell;
